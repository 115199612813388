import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../css/textPage.css"
import Image from "../components/image"

const Labestation = () => {
    return (
        <Layout>
            <SEO title="Labestation" />
            <div className="textPage">
                <h1>Einkehren. <br/> Pause machen.</h1>
                <div className="textPageInfo">
                    <p>Gönnen Sie sich eine Pause von Ihrer Fahrradtour und kehren Sie an unserer Labestation ein. Kommen Sie in einem schattigen Platzerl und kühlen Getränken wieder zu Kräften.</p>
                    <a className="link"
                       href="https://www.google.com/maps/place/Halbenrain+188,+8492+Halbenrain/@46.7338208,15.9466393,17z/data=!3m1!4b1!4m13!1m7!3m6!1s0x476f11922e39faa1:0x601f59ee709dbabd!2sHalbenrain+188,+8492+Halbenrain!3b1!8m2!3d46.7338208!4d15.948828!3m4!1s0x476f11922e39faa1:0x601f59ee709dbabd!8m2!3d46.7338208!4d15.948828">8492
                        Halbenrain 188</a>
                    <div className="singleImageContainer">
                        <Image alt="Labestation" filename="IMG_2136.jpg" />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Labestation